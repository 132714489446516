export const navigationLinkItem = (item: object) => {
  if (!item) { return }
  if (item instanceof Object === false) { return }

  const mainPage = item?.main_page

  let firstFilledType = null
  for (const [key, value] of Object.entries(item)) {
    const attributes = value?.data?.attributes|| value?.attributes
    if (value instanceof Object && attributes) {
      firstFilledType = {
        uid: key,
        ...attributes
      }
      break
    }
  }

  if (!mainPage && !firstFilledType) { return null }

  const routeReference = strapiGetRouteReference(mainPage || firstFilledType?.uid)

  const slugs = firstFilledType?.slugs || {}

  return {
    titles: !mainPage && firstFilledType?.titles ? firstFilledType?.titles : mainPage ? item?.titles : null,
    slugs,
    routeReference: routeReference
  }
}
