<template>
  <div
    :class="$style.root"
    :style="includeAspectRatio ? `--aspect-ratio: ${aspectRatioAndOrientation?.aspectRatio};` : null"
    :data-orientation="aspectRatioAndOrientation?.orientation"
  >
    <div :class="$style.videoContainer">
      <CoreMuxVideoDefaultComponent
        :video="video"
        :poster="poster"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  video: {
    type: Object
  },
  includeAspectRatio: {
    type: Boolean,
    default: true
  }
})

const aspectRatioAndOrientation = getMuxOrientation(props?.video)

const posterWidth = 1000
const posterHeight = Math.round(posterWidth / (aspectRatioAndOrientation?.width / aspectRatioAndOrientation?.height))
const poster = computed(() => {
  return `https://image.mux.com/${props?.video?.attributes?.playback_id}/thumbnail.png?width=${posterWidth}&height=${posterHeight}&fit_mode=smartcrop&time=0`
})
</script>

<style module lang="scss">
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}

.root[data-orientation="portrait"] .videoContainer {
  width: 50%;
}

.videoContainer {
  aspect-ratio: var(--aspect-ratio);
  overflow: hidden;

  width: 100%;
  height: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
